.search-container {
    padding-top: 50px;
}

.search-bar-row {
    height: 100px;
}

.search-bar-col {
    padding: 20px 0px;
}

.search-bar-form{
    height: 100%;
    padding: 10px 0px;
}

.search-text-form-group {
    flex-grow: 10 !important;
    padding: 0px 5px;
}

.search-text {
    flex: 1;
}

.search-button-form-group {
    flex-grow: 1 !important;
    padding: 0px 5px;
}

.search-button {
    flex: 1 ;
}

.search-result-col {
    padding: 0px;
}

.search-results-row {
    border: 0px blue solid;
}