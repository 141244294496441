body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.some-margin {
    margin-top: 50px;
    margin-bottom: 10px;
    padding-left: 50px;
    padding-right: 50px;
}

.margin-tb-10px {
    margin-top: 10px;
    margin-bottom: 10px;
}

.margin-r-5px {
    margin-right: 5px;
}

.make-multiline{
    word-wrap: break-word;
}

.search-text-form-group {
    flex-grow: 10 !important;
}

.search-text {
    flex: 1 1;
}

.search-button-form-group {
    flex-grow: 1 !important;
}

.search-button {
    flex: 1 1;
}

pre > code {
    font-size: 87.5%;
    color: #e83e8c;
    word-break: break-word;
}

.dashboard-container {
    display: flex;
    justify-content: space-around;
}

.dashboard-item:hover {
    background-color: white;
    color: #007bff;
    cursor: default !important; 
}
.link-card {
    height: 100px;
    width: 350px;
    margin: 10px auto;
    box-shadow: 0 0 3px #33333357;
}

.link-title {
    margin: 0 auto;
    padding: 5px;
}

.link-body {
    margin: 0 auto;
}
.links-container {
    padding-top: 50px;
}

.page-title {
    text-align: center;
}
.RichEditor-root {
    background: #fff;
    border: 1px solid #ddd;
    font-family: 'Georgia', serif;
    font-size: 14px;
    padding: 15px;
  }
  
  .RichEditor-editor {
    border-top: 1px solid #ddd;
    cursor: text;
    font-size: 16px;
    margin-top: 10px;
  }
  
  .RichEditor-editor .public-DraftEditorPlaceholder-root,
  .RichEditor-editor .public-DraftEditor-content {
    margin: 0 -15px -15px;
    padding: 15px;
  }
  
  .RichEditor-editor .public-DraftEditor-content {
    min-height: 100px;
  }
  
  .RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
    display: none;
  }
  
  .RichEditor-editor .RichEditor-blockquote {
    border-left: 5px solid #eee;
    color: #666;
    font-family: 'Hoefler Text', 'Georgia', serif;
    font-style: italic;
    margin: 16px 0;
    padding: 10px 20px;
  }
  
  .RichEditor-editor .public-DraftStyleDefault-pre {
    background-color: rgba(0, 0, 0, 0.05);
    font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
    font-size: 16px;
    padding: 20px;
  }
  
  .RichEditor-controls {
    font-family: 'Helvetica', sans-serif;
    font-size: 14px;
    margin-bottom: 5px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  
  .RichEditor-styleButton {
    color: #999;
    cursor: pointer;
    margin-right: 16px;
    padding: 2px 0;
    display: inline-block;
  }
  
  .RichEditor-activeButton {
    color: #5890ff;
  }
.page-title {
    height: 100px;
}

.title {
    height: 50px;
    position: relative;
    top: 25px;
}

.link-item{
    margin-top: 10px;
    margin-bottom: 10px;
}
.search-container {
    padding-top: 50px;
}

.search-bar-row {
    height: 100px;
}

.search-bar-col {
    padding: 20px 0px;
}

.search-bar-form{
    height: 100%;
    padding: 10px 0px;
}

.search-text-form-group {
    flex-grow: 10 !important;
    padding: 0px 5px;
}

.search-text {
    flex: 1 1;
}

.search-button-form-group {
    flex-grow: 1 !important;
    padding: 0px 5px;
}

.search-button {
    flex: 1 1;
}

.search-result-col {
    padding: 0px;
}

.search-results-row {
    border: 0px blue solid;
}
