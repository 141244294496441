.page-title {
    height: 100px;
}

.title {
    height: 50px;
    position: relative;
    top: 25px;
}

.link-item{
    margin-top: 10px;
    margin-bottom: 10px;
}