.some-margin {
    margin-top: 50px;
    margin-bottom: 10px;
    padding-left: 50px;
    padding-right: 50px;
}

.margin-tb-10px {
    margin-top: 10px;
    margin-bottom: 10px;
}

.margin-r-5px {
    margin-right: 5px;
}

.make-multiline{
    word-wrap: break-word;
}

.search-text-form-group {
    flex-grow: 10 !important;
}

.search-text {
    flex: 1;
}

.search-button-form-group {
    flex-grow: 1 !important;
}

.search-button {
    flex: 1 ;
}

pre > code {
    font-size: 87.5%;
    color: #e83e8c;
    word-break: break-word;
}

.dashboard-container {
    display: flex;
    justify-content: space-around;
}

.dashboard-item:hover {
    background-color: white;
    color: #007bff;
    cursor: default !important; 
}