.link-card {
    height: 100px;
    width: 350px;
    margin: 10px auto;
    box-shadow: 0 0 3px #33333357;
}

.link-title {
    margin: 0 auto;
    padding: 5px;
}

.link-body {
    margin: 0 auto;
}